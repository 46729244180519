import React, {useEffect, useState} from 'react';
import CustomTable from "../../components/CustomTable";
import {Alert, Slide, Snackbar} from "@mui/material";
import "./AtdMockOrdersPage.css";
import client from "../../client";
import {OrderList, OrderListResponse} from "../../interfaces/OrderList";

const AtdMockOrdersPage = () => {

    const tsyMockServerDomain = process.env.REACT_APP_TSY_MOCK_SERVER_DOMAIN;

    const [listOfOrders, setListOfOrders] = useState<OrderList[]>([]);
    const [shipAllAlert, setShipAllAlert] = useState<boolean>(false);
    const [deliverAllAlert, setDeliverAllAlert] = useState<boolean>(false);
    const [refreshOmsAlert, setRefreshOmsAlert] = useState<boolean>(false);

    const shipAll = async (purchaseOrderNumber: string) => {
        fetch(`${tsyMockServerDomain}/api/atd/order/ship-all?purchaseOrderNumber=${purchaseOrderNumber}`)
            .then(response => {
                if (response.ok) {
                    setShipAllAlert(true);
                }
            });
    }

    const deliverAll = async (purchaseOrderNumber: string) => {
        fetch(`${tsyMockServerDomain}/api/atd/order/deliver-all?purchaseOrderNumber=${purchaseOrderNumber}`)
            .then(response => {
                if (response.ok) {
                    setDeliverAllAlert(true);
                }
            });
    }

    const refreshOms = async (purchaseOrderNumber: string) => {
        client.utilFunctions.publishOrderEvent(purchaseOrderNumber, "axis.orders.fetch-order-details")
            .then(() => {
                setRefreshOmsAlert(true);
            });
    }

    const refreshOrderList = () => {
        fetch(`${tsyMockServerDomain}/api/atd/orders`)
            .then(response => {
                if (response.ok) {
                    response.json().then(responseBody => setListOfOrders(responseBody.orders.map((order: OrderListResponse) => {
                        return {
                            ...order,
                            id: order.confirmationNumber.split("-")[1],
                            shipAllFunction: "",
                            deliverAllFunction: "",
                            refreshTbSystemFunction: ""
                        }
                    })));
                }
            });
    }

    useEffect(() => {
        refreshOrderList();
    }, []);

    if (listOfOrders.length > 0)
        return (
            <div className="AtdMockOrdersPage">
                <CustomTable
                    rows={listOfOrders}
                    onClickShipAll={shipAll}
                    onClickDeliverAll={deliverAll}
                    onClickRefreshOms={refreshOms}
                />
                <Snackbar
                    open={shipAllAlert}
                    onClose={() => setShipAllAlert(false)}
                    autoHideDuration={3000}
                    TransitionComponent={TransitionRight}
                >
                    <Alert severity="success">All items have been marked as shipped</Alert>
                </Snackbar>
                <Snackbar
                    open={deliverAllAlert}
                    onClose={() => setDeliverAllAlert(false)}
                    autoHideDuration={3000}
                    TransitionComponent={TransitionRight}
                >
                    <Alert severity="success">All items have been marked as delivered</Alert>
                </Snackbar>
                <Snackbar
                    open={refreshOmsAlert}
                    onClose={() => setRefreshOmsAlert(false)}
                    autoHideDuration={3000}
                    TransitionComponent={TransitionRight}
                >
                    <Alert severity="success">Message has been pushed to the fetch order details event</Alert>
                </Snackbar>
            </div>
        );
    else
        return <div className="loading">Loading...</div>
};

const TransitionRight = (props: any) => {
    return <Slide {...props} direction="right"/>;
}

export default AtdMockOrdersPage;